/* #ffa500 */
@import url(https://fonts.googleapis.com/css?family=Quicksand:400,300);
/** NAVBAR **/
.navbar {
  border-bottom: 2px solid #0077a1;
}

.navbar-default {
  background-color: #fafafa;
  padding: 0 5%;
  z-index: 1;
}

#navbarContainer {
  padding: 0;
}

/** END NAVBAR **/
/** LOGIN FORM **/
.modal-dialog {
  width: 250px;
  text-align: center;
}

.modal-header, .modal-footer {
  background: #0077a1;
  color: #fff;
}

input#username {
  margin-bottom: 20px;
}

/** END LOGIN FORM **/
.navbar-default .navbar-nav > li > a {
  color: #00516e;
}

/*** FONT STYLES ***/
body {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1.5em;
}

.heroSection h1 {
  line-height: 75px;
}
.heroSection h2 {
  line-height: 50px;
}
.heroSection section {
  margin-top: 4em;
}

.padTopBtm {
  padding: 3em 0em;
}

.marTopBtm {
  margin-top: 4em;
  margin-bottom: 3em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Slabo 27px', serif;
  line-height: 35px;
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

h4 {
  line-height: 25px;
}

/*** END FONT STYLES ***/
.accTxt {
  color: orange;
}

.heroSection .overlay {
  height: 100vh;
}
.heroSection .whtOverlay {
  background-color: rgba(250, 250, 250, 0.7);
  padding: 35vh 0vw;
  box-shadow: 0px 0px 15px rgba(50, 50, 50, 0.7);
}

.overlay {
  height: 100vh;
  position: relative;
}

.whtOverlay {
  background-color: rgba(250, 250, 250, 0.9);
  box-shadow: 0px 0px 15px rgba(50, 50, 50, 0.7);
}

.txtVert {
  position: absolute;
  top: 30vh;
}

footer {
  color: #333;
  padding: 1em 0em;
}
footer a {
  color: #333;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  background: #0077a1;
  color: #fff;
}

.bg-primary {
  background-color: #0077a1;
}

.bg-secondary {
  background-color: #00FFB6;
  color: #fafafa;
}

.primaryTxt,
a {
  color: #0077a1;
}

.secondaryTxt {
  color: #00FFB6;
}

.bold {
  font-weight: bold;
}

.hrPrimary {
  border-color: #0077a1 -moz-use-text-color;
}

.hrSecondary {
  border-color: orange -moz-use-text-color;
}

hr {
  width: 50%;
}

cite {
  float: right;
  color: orange;
}

blockquote {
  border-color: orange;
}

.btn-default {
  background: #00FFB6;
  color: #fafafa;
  padding: 2% 5%;
  border: none;
  font-weight: bold;
}

.btn-default:hover {
  background: #33ffc5;
  color: #fff;
  border: none;
  font-weight: bold;
}

.btn-default2 {
  background: #0077a1;
  color: #fafafa;
  border: none;
  font-weight: bold;
  transition-duration: 0.5s;
}

.btn-default2:hover {
  border: none;
  border-radius: 5em;
}

.btn-xl {
  font-family: 'Quicksand', sans-serif;
  text-transform: uppercase;
  box-shadow: 0px 0px 5px #333;
  padding: 1.5em 3em;
}

.whtTxt, a.whtTxt {
  color: #fff;
}

.boxShadow {
  box-shadow: 0px 0px 15px rgba(50, 50, 50, 0.7);
}

.txtShadow {
  text-shadow: 0px 0px 5px #333;
}

.whtTxtShadow {
  text-shadow: 0px 0px 5px #fff;
}

.icon {
  display: block;
  margin: 0 auto;
}

h1, h2, h3 {
  font-weight: bold;
}

.bgMid {
  background: url(/images/coupleMid.jpg);
}

.bgLow {
  background: url(/images/coupleLow.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.heroSection {
  background: url(/images/couple_waterWEB2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#membersImg {
  margin-top: 10%;
}

.img-responsive.logo {
  width: 400px;
}
@media (max-width: 991px) {
  .img-responsive.logo {
    width: 275px;
    margin-top: -15px;
  }
}
@media (max-width: 420px) {
  .img-responsive.logo {
    width: 175px;
    margin-top: -7px;
  }
}
@media (max-width: 305px) {
  .img-responsive.logo {
    width: 150px;
    margin-top: -7px;
  }
}
